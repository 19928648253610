import React from "react";

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import Layout from "../components/layout";

// http://gpwidget-guides-prod.s3-website.eu-west-2.amazonaws.com/
//http://gpwidget-app-prod.s3-website.eu-west-2.amazonaws.com/

function Index({ user }) {
  return (
    <div>
      <Layout></Layout>
    </div>
  );
}

export default withAuthenticator(Index);
